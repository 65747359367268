var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-row",
        { staticClass: "mt-n1 ml-0 mr-0" },
        [
          _c("b-col", { staticClass: "pb-2 pt-2", attrs: { cols: "11" } }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "d-flex align-items-center mr-3 mb-1" },
                [
                  _c(
                    "b-avatar",
                    { attrs: { variant: "light-primary", rounded: "" } },
                    [
                      _c("feather-icon", {
                        attrs: { icon: "FileIcon", size: "18" }
                      })
                    ],
                    1
                  ),
                  _c("div", { staticClass: "ml-1" }, [
                    _c("h4", { staticClass: "mb-0 font-weight-bolder " }, [
                      _vm._v(" 246 ")
                    ]),
                    _c("small", [_vm._v("Total Statements")])
                  ])
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "d-flex align-items-center mr-3 mb-1" },
                [
                  _c(
                    "b-avatar",
                    { attrs: { variant: "light-primary", rounded: "" } },
                    [
                      _c("feather-icon", {
                        attrs: { icon: "DollarSignIcon", size: "18" }
                      })
                    ],
                    1
                  ),
                  _c("div", { staticClass: "ml-1" }, [
                    _c("h4", { staticClass: "mb-0 font-weight-bolder" }, [
                      _vm._v(" £125,750.00 ")
                    ]),
                    _c("small", [_vm._v("Total Paid")])
                  ])
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "d-flex align-items-center mr-3 mb-1" },
                [
                  _c(
                    "b-avatar",
                    { attrs: { variant: "light-success", rounded: "" } },
                    [
                      _c("feather-icon", {
                        attrs: { icon: "CreditCardIcon", size: "18" }
                      })
                    ],
                    1
                  ),
                  _c("div", { staticClass: "ml-1" }, [
                    _c("ul", { staticClass: "list-inline mb-0" }, [
                      _c("li", { staticClass: "list-inline-item" }, [
                        _c("h4", { staticClass: "mb-0 font-weight-bolder" }, [
                          _vm._v("£18,950.00")
                        ])
                      ]),
                      _c("li", { staticClass: "list-inline-item" }, [
                        _c(
                          "small",
                          {
                            staticClass: "text-success font-weight-bold small"
                          },
                          [_vm._v("+17%")]
                        )
                      ])
                    ]),
                    _c("small", [_vm._v("Paid This Month")])
                  ])
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "d-flex align-items-center mr-3 mb-1" },
                [
                  _c(
                    "b-avatar",
                    { attrs: { variant: "light-danger", rounded: "" } },
                    [
                      _c("feather-icon", {
                        attrs: { icon: "AlertCircleIcon", size: "18" }
                      })
                    ],
                    1
                  ),
                  _c("div", { staticClass: "ml-1" }, [
                    _c("h4", { staticClass: "mb-0 font-weight-bolder" }, [
                      _vm._v("6 ")
                    ]),
                    _c("small", [_vm._v("Unpaid Statements")])
                  ])
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "d-flex align-items-center mr-3 mb-1" },
                [
                  _c(
                    "b-avatar",
                    { attrs: { variant: "light-danger", rounded: "" } },
                    [
                      _c("feather-icon", {
                        attrs: { icon: "CreditCardIcon", size: "18" }
                      })
                    ],
                    1
                  ),
                  _c("div", { staticClass: "ml-1" }, [
                    _c("h4", { staticClass: "mb-0 font-weight-bolder" }, [
                      _vm._v(" £11,300.00 ")
                    ]),
                    _c("small", [_vm._v("Total Unpaid")])
                  ])
                ],
                1
              )
            ])
          ]),
          _c(
            "b-col",
            {
              staticClass: "pt-0 pb-2 pt-xl-2 pb-xl-0 ml-n1 ",
              attrs: { cols: "1" }
            },
            [
              _c("b-row", [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex justify-content-end  mt-2 mt-md-0 pl-0 pl-md-0 ml-auto"
                  },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "btn-icon bg-white text-primary",
                        attrs: { size: "23", variant: "outline-white" }
                      },
                      [
                        _c("feather-icon", {
                          attrs: { size: "18", icon: "DownloadIcon" }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }