var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-row",
        { staticClass: "mt-n1 ml-0 mr-0" },
        [
          _c("b-col", { staticClass: "pb-2 pt-2", attrs: { cols: "11" } }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "d-flex align-items-center mr-3 mb-1" },
                [
                  _c(
                    "b-avatar",
                    { attrs: { rounded: "", variant: "light-primary" } },
                    [
                      _c("feather-icon", {
                        attrs: { icon: "FileIcon", size: "18" }
                      })
                    ],
                    1
                  ),
                  _c("div", { staticClass: "ml-1" }, [
                    _c("h4", { staticClass: "mb-0 font-weight-bolder " }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.invoiceSummary.total_statements_count) +
                          " "
                      )
                    ]),
                    _c("small", [_vm._v("Total Statements")])
                  ])
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "d-flex align-items-center mr-3 mb-1" },
                [
                  _c(
                    "b-avatar",
                    { attrs: { rounded: "", variant: "light-primary" } },
                    [
                      _c("feather-icon", {
                        attrs: { icon: "DollarSignIcon", size: "18" }
                      })
                    ],
                    1
                  ),
                  _c("div", { staticClass: "ml-1" }, [
                    _c("h4", { staticClass: "mb-0 font-weight-bolder" }, [
                      _vm._v(" £" + _vm._s(_vm.invoiceSummary.total_paid) + " ")
                    ]),
                    _c("small", [_vm._v("Total Paid")])
                  ])
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "d-flex align-items-center mr-3 mb-1" },
                [
                  _c(
                    "b-avatar",
                    { attrs: { rounded: "", variant: "light-success" } },
                    [
                      _c("feather-icon", {
                        attrs: { icon: "CreditCardIcon", size: "18" }
                      })
                    ],
                    1
                  ),
                  _c("div", { staticClass: "ml-1" }, [
                    _c("ul", { staticClass: "list-inline mb-0" }, [
                      _c("li", { staticClass: "list-inline-item" }, [
                        _c("h4", { staticClass: "mb-0 font-weight-bolder" }, [
                          _vm._v(
                            "£" +
                              _vm._s(_vm.invoiceSummary.total_paid_this_month)
                          )
                        ])
                      ])
                    ]),
                    _c("small", [_vm._v("Paid This Month")])
                  ])
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "d-flex align-items-center mr-3 mb-1" },
                [
                  _c(
                    "b-avatar",
                    { attrs: { rounded: "", variant: "light-danger" } },
                    [
                      _c("feather-icon", {
                        attrs: { icon: "AlertCircleIcon", size: "18" }
                      })
                    ],
                    1
                  ),
                  _c("div", { staticClass: "ml-1" }, [
                    _c("h4", { staticClass: "mb-0 font-weight-bolder" }, [
                      _vm._v(
                        _vm._s(
                          _vm.invoiceSummary.total_un_paid_statements_count
                        ) + " "
                      )
                    ]),
                    _c("small", [_vm._v("Unpaid Statements")])
                  ])
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "d-flex align-items-center mr-3 mb-1" },
                [
                  _c(
                    "b-avatar",
                    { attrs: { rounded: "", variant: "light-danger" } },
                    [
                      _c("feather-icon", {
                        attrs: { icon: "CreditCardIcon", size: "18" }
                      })
                    ],
                    1
                  ),
                  _c("div", { staticClass: "ml-1" }, [
                    _c("h4", { staticClass: "mb-0 font-weight-bolder" }, [
                      _vm._v(
                        " £" + _vm._s(_vm.invoiceSummary.total_un_paid) + " "
                      )
                    ]),
                    _c("small", [_vm._v("Total Unpaid")])
                  ])
                ],
                1
              )
            ])
          ]),
          _c(
            "b-col",
            {
              staticClass: "pt-0 pb-2 pt-xl-2 pb-xl-0 ml-n1 ",
              attrs: { cols: "1" }
            },
            [
              _c("b-row", [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex justify-content-end  mt-2 mt-md-0 pl-0 pl-md-0 ml-auto"
                  },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "btn-icon bg-white text-primary",
                        attrs: { size: "23", variant: "outline-white" }
                      },
                      [
                        _c("feather-icon", {
                          attrs: { icon: "DownloadIcon", size: "18" }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-card",
        { staticClass: "mp-0" },
        [
          _c(
            "div",
            { staticClass: "row" },
            [
              _c(
                "b-col",
                { staticClass: "mb-1", attrs: { cols: "12", md: "4" } },
                [
                  _c(
                    "b-form-group",
                    [
                      _c(
                        "b-input-group",
                        { staticClass: "input-group-merge" },
                        [
                          _c(
                            "b-input-group-prepend",
                            { attrs: { "is-text": "" } },
                            [
                              _c("feather-icon", {
                                attrs: { icon: "SearchIcon" }
                              })
                            ],
                            1
                          ),
                          _c("b-form-input", {
                            attrs: { id: "icons-search", placeholder: "Search" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("b-col", { attrs: { cols: "12", md: "6" } }),
              _c(
                "b-col",
                {
                  staticClass: "flex-float-right",
                  attrs: { cols: "12", md: "2" }
                },
                [
                  _c(
                    "b-form-group",
                    [
                      _c("v-select", {
                        attrs: {
                          dir: _vm.$store.state.appConfig.isRTL ? "rtl" : "ltr",
                          options: _vm.option1,
                          label: "title1",
                          placeholder: "February, 2021"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "b-overlay",
                    { attrs: { show: _vm.tableLoading, rounded: "sm" } },
                    [
                      _c("b-table", {
                        ref: "table",
                        staticClass: "mobile_table_css",
                        attrs: {
                          "current-page": _vm.currentPage,
                          fields: _vm.fields,
                          items: _vm.getInvoices,
                          "per-page": _vm.pagination.perPage,
                          "sort-by": _vm.sortBy,
                          "sort-desc": _vm.sortDesc,
                          "sort-direction": _vm.sortDirection,
                          hover: "",
                          responsive: ""
                        },
                        on: {
                          "update:sortBy": function($event) {
                            _vm.sortBy = $event
                          },
                          "update:sort-by": function($event) {
                            _vm.sortBy = $event
                          },
                          "update:sortDesc": function($event) {
                            _vm.sortDesc = $event
                          },
                          "update:sort-desc": function($event) {
                            _vm.sortDesc = $event
                          },
                          "row-clicked": _vm.rowClicked
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "cell(user_name)",
                            fn: function(data) {
                              return [
                                _c("ul", { staticClass: "list-inline mb-0" }, [
                                  _c("li", { staticClass: "list-inline-item" }),
                                  _c(
                                    "li",
                                    { staticClass: "list-inline-item" },
                                    [_vm._v(" " + _vm._s(data.item.user_name))]
                                  )
                                ])
                              ]
                            }
                          },
                          {
                            key: "cell(status)",
                            fn: function(data) {
                              return [
                                _c("span", [_vm._v(_vm._s(data.item.status))])
                              ]
                            }
                          },
                          {
                            key: "cell(action)",
                            fn: function(data) {
                              return [
                                data.item.status === "outstanding"
                                  ? _c(
                                      "b-button",
                                      {
                                        directives: [
                                          {
                                            name: "b-tooltip",
                                            rawName: "v-b-tooltip.hover.top",
                                            value: "Mark as paid",
                                            expression: "'Mark as paid'",
                                            modifiers: {
                                              hover: true,
                                              top: true
                                            }
                                          }
                                        ],
                                        staticClass:
                                          "btn-icon bg-white text-primary m-1 ",
                                        attrs: {
                                          size: "23",
                                          variant: "outline-white"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.markAsPaid(data.item.id)
                                          }
                                        }
                                      },
                                      [
                                        _c("feather-icon", {
                                          attrs: {
                                            icon: "CheckSquareIcon",
                                            size: "18"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                data.item.status === "paid"
                                  ? _c(
                                      "b-button",
                                      {
                                        directives: [
                                          {
                                            name: "b-tooltip",
                                            rawName: "v-b-tooltip.hover.top",
                                            value: "Mark as unpaid",
                                            expression: "'Mark as unpaid'",
                                            modifiers: {
                                              hover: true,
                                              top: true
                                            }
                                          }
                                        ],
                                        staticClass:
                                          "btn-icon bg-white text-danger m-1",
                                        attrs: {
                                          size: "23",
                                          variant: "outline-white"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.markAsUnPaid(
                                              data.item.id
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("feather-icon", {
                                          attrs: {
                                            icon: "XSquareIcon",
                                            size: "18",
                                            variant: "outline-danger"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.pagination.totalRows === 0
                ? _c(
                    "b-col",
                    { staticClass: "text-center", attrs: { cols: "12" } },
                    [_c("span", [_vm._v("No data for preview")])]
                  )
                : _vm._e(),
              _c(
                "b-col",
                {
                  staticClass:
                    "d-flex align-items-center justify-content-center justify-content-sm-start",
                  attrs: { cols: "12", sm: "6" }
                },
                [
                  _vm.pagination.totalRows !== 0
                    ? _c("span", { staticClass: "text-muted" }, [
                        _vm._v(
                          "Showing " +
                            _vm._s(_vm.pagination.from) +
                            " to " +
                            _vm._s(_vm.pagination.to) +
                            " of " +
                            _vm._s(_vm.pagination.totalRows) +
                            " entries"
                        )
                      ])
                    : _vm._e()
                ]
              ),
              _c(
                "b-col",
                {
                  staticClass:
                    "d-flex align-items-center justify-content-center justify-content-sm-end",
                  attrs: { cols: "12", sm: "6" }
                },
                [
                  _c("b-pagination", {
                    staticClass: "mb-0 mt-1 mt-sm-0 ",
                    attrs: {
                      "per-page": _vm.pagination.perPage,
                      "total-rows": _vm.pagination.totalRows,
                      "first-number": "",
                      "last-number": "",
                      "next-class": "next-item",
                      "prev-class": "prev-item"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "prev-text",
                        fn: function() {
                          return [
                            _c("feather-icon", {
                              attrs: { icon: "ChevronLeftIcon", size: "18" }
                            })
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "next-text",
                        fn: function() {
                          return [
                            _c("feather-icon", {
                              attrs: { icon: "ChevronRightIcon", size: "18" }
                            })
                          ]
                        },
                        proxy: true
                      }
                    ]),
                    model: {
                      value: _vm.currentPage,
                      callback: function($$v) {
                        _vm.currentPage = $$v
                      },
                      expression: "currentPage"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }