<template>
  <div class="home">

      <div ><Table /> </div>


 </div>
</template>

<script>
import Pheaderbar from './view/pheaderbar'
import Table from './view/table'


import { BCard} from 'bootstrap-vue'

export default {
  name: 'Home',
  components: {
    Pheaderbar,
    Table,
    BCard,

  },

  data() {
    return {
   }
  },
}
</script>

<style >
</style>

